<template>
  <div class="box">
    <div>
      <div class="box-o">
        <span>{{ item.content }}</span>
      </div>
      <div class="title">
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {
          title: "title",
          content: "content",
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 120px;
  height: 120px;
  display: flex;
  margin-right: 75px;
  flex-direction: column;
  align-items: center;
  &:last-of-type {
    margin-right: 0;
  }
  .box-o {
    width: 180px;
    height: 80px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 10px 0;
    color: red;
    font-weight: bold;
    font-size: 30px;
    overflow: hidden;
  }
    .title {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #BE0107;
  }
}
</style>
